exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Assistant|Roboto+Condensed&display=swap);", ""]);
// Module
exports.push([module.id, "._2VSh9KO6N-HFWvYR59aHOO {\n  display: flex;\n  justify-content: center;\n}\n\n.z3b5hsntmOgzG73lwqAWk {\n  font-family: 'Roboto Condensed', sans-serif;\n  font-size: 4vw;\n  text-align: center;\n  margin-bottom: 2vw;\n  margin-left: 1vw;\n}\n\n@media (min-width: 1024px) {\n\n.z3b5hsntmOgzG73lwqAWk {\n    font-size: 2vw\n}\n  }\n\n.xsouzRlXnH_tmoFT37n2Z {\n  font-family: 'Assistant', sans-serif;\n  font-size: 2.9vw;\n  margin-bottom: 3vw;\n}\n\n@media (min-width: 1024px) {\n\n.xsouzRlXnH_tmoFT37n2Z {\n    font-size: 1.5vw\n}\n  }", "",{"version":3,"sources":["AboutText.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,2CAA2C;EAC3C,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAKlB;;AAHE;;AAPF;IAQI;AAEJ;EADE;;AAGF;EACE,oCAAoC;EACpC,gBAAgB;EAChB,kBAAkB;AAKpB;;AAHE;;AALF;IAMI;AAEJ;EADE","file":"AboutText.css","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Assistant|Roboto+Condensed&display=swap');\n\n.titleContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.title {\n  font-family: 'Roboto Condensed', sans-serif;\n  font-size: 4vw;\n  text-align: center;\n  margin-bottom: 2vw;\n  margin-left: 1vw;\n\n  @media (min-width: 1024px) {\n    font-size: 2vw;\n  }\n}\n\n.textBody {\n  font-family: 'Assistant', sans-serif;\n  font-size: 2.9vw;\n  margin-bottom: 3vw;\n\n  @media (min-width: 1024px) {\n    font-size: 1.5vw;\n  }\n}"]}]);
// Exports
exports.locals = {
	"titleContainer": "_2VSh9KO6N-HFWvYR59aHOO",
	"title": "z3b5hsntmOgzG73lwqAWk",
	"textBody": "xsouzRlXnH_tmoFT37n2Z"
};